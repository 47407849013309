import React from 'react'
import Nav from './Nav'
// import { useNavigate } from 'react-router-dom';
import ContactBottom from './ContactBottom';


function Product() {
    // const navigate = useNavigate()

  return (
    <>    
    <Nav/>
            
    <div className='App'>

  <div className='studyDiv'>
        <div className='courses'>
            <h1>Amazon Products</h1>
            <div className='ppl'>
                </div>
                </div>
        </div>
    </div>

    <ContactBottom/>

    </>
    

  )
}

export default Product